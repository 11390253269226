import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import SingleFeatures from '../components/Features/SingleFeatures';
import PeraOzellikler from "../components/Features/PeraOzellikler";
import PeraOzellikler2 from "../components/Features/PeraOzellikler2";
import { Link } from "gatsby";
import Shape1 from "../assets/images/shape1.png";
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";
import Shape5 from "../assets/images/shape5.png";

const MobilSuSiparis = () => (
    <Layout>
        <SEO title="Pera PDKS Yazılımı" />

        <Navbar />

        <div className="page-title-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <h2>PERA Pdks Yazılımı</h2>
                        <h5>
                            <Link to="https://pera.pixelyazilim.com.tr/">
                                Demo İçin Tıklayınız
                            </Link>
                        </h5>
                    </div>
                </div>
            </div>

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape5">
                <img src={Shape5} alt="shape" />
            </div>
            <div className="shape6 rotateme">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>



        <PeraOzellikler />

        <PeraOzellikler2 />

        <Footer />
    </Layout>
)

export default MobilSuSiparis;
